<template>
  <div class="d-flex flex-column max-height-100">
    <div class="d-flex flex-grow-1 fill-height" :style="{ height: `calc(100vh - ${headerHeight}px)` }">
      <iframe
        v-if="!isLoading && nodeRedUrl"
        ref="nodeRedIframe"
        :src="nodeRedUrl"
        width="100%"
        height="100%"
        style="border: none;"
        @load="sendMessageToIframe"
      ></iframe>

      <!-- Muestra un mensaje de carga mientras se obtiene la URL -->
      <div v-else-if="isLoading" class="loading-message">
        {{ this.$t('automationTool.loading') }}
      </div>
    
      <!-- Si no hay nodeRedUrl, muestra el mensaje de error -->
      <div v-else class="error-wrapper">
        <div class="error-card">
          <!-- style="height: auto;" -->
          <h2 class="error-title">⚠ {{ this.$t('automationTool.automationEngineNoAccess') }}</h2>
          <p class="error-message">
            {{ this.$t('automationTool.automationEngineErrorMessage') }}
          </p>
          <button class="retry-btn" @click="retryConnection">
            🔄 Reintentar
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

// Llevarlo a una clase que cuelgue de la carpeta common
import axios from 'axios';

export default {
  name: "NodeRedEmbed",
  data() {
    return {
      nodeRedUrl: null,
      isLoading: true // Variable para controlar la carga
    };
  },
  beforeCreate() {
    // Log en beforeCreate
    console.log("beforeCreate: No hay acceso a 'data'");
  },
  created() {
    // Log en created
    console.log("created: Ahora sí hay acceso a 'data'");
  },
  computed: {
    headerHeight() {
      return this.$store.state.global.appHeaderMapHeightInPx;
    }
  },
  mounted() {
    this.loadNodeRedUrl(); // Llama a la función para volver a cargar la URL
	},
	beforeDestroy() {
		console.log('info: beforedestroy dashboardpanel');

		if (document.getElementsByClassName('pui-containerHeader')[0]) {
			document.getElementsByClassName('pui-containerHeader')[0].style.display = '';
		}
	},
  methods: {
    sendMessageToIframe() {
      const iframe = this.$refs.nodeRedIframe;
      const iframeWindow = iframe.contentWindow;

      // Enviar mensaje al iframe para cambiar el texto del header
      setTimeout(() => {
        iframeWindow.postMessage(
          {
            type: 'changeHeaderText',
            text: this.$t('automationTool.title')
          },
          //`${constants.baseURL_AUTOMATION_TOOL}:1895`
          this.nodeRedUrl
        );
        //
        
      }, 3000); // Esperar 3 segundos
    }, //end sendMessageToIframe

    async retryConnection() {
      this.loadNodeRedUrl(); // Llama a la función para volver a cargar la URL
    },

    async loadNodeRedUrl() {
      this.isLoading = true; // Inicia el estado de carga
      this.nodeRedUrl = null; // Reinicia la URL para mostrar el mensaje de carga

      try {
        console.log('info: mounted dashboardpanel');
        var userProperties = this.$store.getters.getUserProperties;
        console.log('userProperties', userProperties);

        const token = userProperties.organizationtoken; // Obtén el token del store
        console.log('token', token);

        var appProperties = this.$store.getters.getApplication;
        console.log('App properties:', appProperties);
        const baseUrl = appProperties.urlbase;

        const proxyUrl = '/api'; // URL del proxy definido en vue.config.js
        const config = {
          url: proxyUrl, // Apunto al proxy
          headers: { 
            Authorization: `Bearer ${token}`, // Agrego el token en el header
            'baseUrl': baseUrl
          }
        }

        const response = await axios.get(`${config.url}`, { headers: config.headers });
        console.log('\nRespuesta proxy dinamico: ', response);
        this.nodeRedUrl = response.data.url; // Configura la URL del iframe
      } catch (error) {
        console.error('Error al cargar la URL de Node-RED', error);
      } finally {
        this.isLoading = false; // Finaliza el estado de carga
      }
      const puiContainerHeader = document.getElementsByClassName('pui-containerHeader')[0];
      puiContainerHeader.style.display = 'none';
    }
  }
};
</script>

<style scoped>

/* div {
  height: 100vh;
} */

/* Contenedor mensaje de carga */
.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background: #fff; */
  font-size: 18px;
  /* color: #555; */
}

/* Contenedor centralizado */
.error-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}

/* Tarjeta de error */
.error-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 25px;/* Evita que se pegue a los bordes en pantallas pequeñas */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid rgb(1, 78, 250); /* Borde azul */
  text-align: center;
  min-width: 280px; /* Ancho mínimo para pantallas pequeñas */
  max-width: 400px; /* Ancho máximo para pantallas grandes */
  width: auto; /* Ancho automático para adaptarse al contenido */
}

/* Título */
.error-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Mensaje */
.error-message {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

/* Botón de reintento */
.retry-btn {
  /* background: #007bff; */
  background: rgb(1, 78, 250);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.retry-btn:hover {
  /* background: #0056b3; */
  background: rgb(1, 78, 250);
}

</style>